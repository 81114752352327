import React from 'react'
import '../css/MisCobros.css'
import Footer from '../components/Footer'

const MisCobros = ({footerClass, footerHome, footerNovedades, footerTelefono}) => {
  return (
    <div id="app" className='app-mis-cobros'>
      <div id="misCobrosHeader">
        <img id='atras' src={require('../img/mis-cobros/atras.webp')} alt="" onClick={footerHome} />
        <p>Mis cobros</p>
      </div>

      <div id="misCobrosHeader-continue">
        <h2>Consultá las fechas de tus cobros.</h2>
        <img src={require('../img/mis-cobros/cobros.webp')} alt="" />
      </div>

      <div id="mis-cobros">
        <img src={require('../img/mis-cobros/cobros-big-logo.webp')} alt="" />
        <p id="title">No tenés cobros asignados</p>

        <div id="divider-line"></div>

        <p id="description">En esta sección podés ver, si te corresponde, las fechas de cobro de los programas a los que estés adherido.</p>
      </div>

      <Footer 
        footerClass={footerClass}
        footerHome={footerHome}
        footerNovedades={footerNovedades}
        footerTelefono={footerTelefono} />
    </div>
  )
}

export default MisCobros