import React from 'react'
import '../css/MiSalud.css'
import Footer from '../components/Footer'

const MiSalud = ({ footerClass, footerHome, footerNovedades, footerTelefono }) => {
  return (
    <div id="app" className='app-mi-salud'>
      <div id="miSaludHeader">
        <img src={require('../img/mi-salud/atras.webp')} alt="" id="atras" onClick={footerHome} />
        <p>Mi salud</p>
      </div>

      <div id="miSaludHeader-continue">
        <h2>Tu información de salud siempre con vos.</h2>
        <img src={require('../img/mi-salud/tu-salud.webp')} alt="" />
      </div>

      <div id="saludSectionContainer">
        <div className="saludSection" id='misalud-cobertura' style={{ position: 'relative', top: '-20px' }}>
          <p id='title'>Cobertura de Salud</p>
          <div id="divider-line"></div>
          <div id='detectCobertura'>
            <img src={require('../img/mi-salud/cobertura.webp')} alt="" />
            <p>No se registra cobertura actualmente</p>
          </div>
        </div>

        <div id="divider-line"></div>

        <p className='section-title'>Mis credenciales</p>
        <div className="saludSection" id='misalud-organDonation'>
          <div id="donation">
            <img src={require('../img/mi-salud/organ-donation.webp')} alt="" />
            <div id="divider-line2"></div>
            <p>Donación de órganos</p>
          </div>

          <div id="divider-line"></div>

          <p id='donationText'>Expresá tu voluntad hacia la donación. Es fácil y rápido.</p>

          <button>
            <p>Expresá tu voluntad de donar</p>
          </button>

          <div id="divider-line"></div>

          <p id='suministratedBy'>Información suministrada por</p>
          <p id='incucai'>INCUCAI</p>
        </div>

        <div id="divider-line"></div>

        <p className="section-title">Más información de salud</p>
        <div className="saludSection" id="misalud-vacunas">
          <div id="vacunas">
            <img src={require('../img/mi-salud/vacunas.webp')} alt="" />
            <div id="divider-line2"></div>
            <p>Mis vacunas</p>
          </div>

          <p className='vacunas-text'>Consultá tus vacunas de calendario registradas en el Ministerio de Salud de la Nación.</p>
          <p className='vacunas-text'>Es posible que algunas dosis aplicadas no se muestren porque el registro es obligatorio desde el 2023.</p>

          <button>
            <p>Ver mis vacunas</p>
          </button>
        </div>
      </div>

      <Footer
        footerClass={footerClass}
        footerNovedades={footerNovedades}
        footerHome={footerHome}
        footerTelefono={footerTelefono} />
    </div>
  )
}

export default MiSalud