import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import '../css//Home.css';

const Home = ({ userName, goToPage, footerClass, footerNovedades, goToPage2, goToPage3, footerTelefono, goToPage4, goToPage5, goToPage6, goToPage7, goToPage8, goToPage9 }) => {
  let servicekey = 0;

  const servicios = [
    {
      name: 'Mis documentos',
      img: require('../img/servicios/documentos.webp'),
      color: '#3772bd',
      onclick: () => goToPage()
    },
    {
      name: 'Mis Vehículos',
      img: require('../img/servicios/vehiculos.webp'),
      color: '#f49f3a',
      onclick: () => goToPage3()
    },
    {
      name: 'Mi salud',
      img: require('../img/servicios/salud.webp'),
      color: '#ff4199',
      onclick: () => goToPage4()
    },
    {
      name: 'Mi trabajo',
      img: require('../img/servicios/trabajo.webp'),
      color: '#c4ce28',
      onclick: () => goToPage5()
    },
    {
      name: 'Mis turnos',
      img: require('../img/servicios/turnos.webp'),
      color: '#21cec2',
      onclick: () => goToPage6()
    },
    {
      name: 'Mis cobros',
      img: require('../img/servicios/cobros.webp'),
      color: '#9fc5d8',
      onclick: () => goToPage7()
    },
    {
      name: 'Mis trámites',
      img: require('../img/servicios/tramites.webp'),
      color: '#8e79cb',
      onclick: () => goToPage8()
    },
    {
      name: 'Mis hijos',
      img: require('../img/servicios/hijos.webp'),
      color: '#ebb507',
      onclick: () => goToPage9()
    }
  ];

  return (
    <div id='app' className='app-home'>
      <Header goToPage={() => goToPage2()} />

      <div id="header-continue">
        <p id='user-welcome'>¡Hola, {userName.split(' ')[0].charAt(0).toUpperCase() + userName.split(' ')[0].slice(1).toLowerCase()}!</p>
      </div>

      <div id="turnos">
        <img src={require('../img/turnos.webp')} alt="" />
        <div id="text-turnos">
          <p id='main-turnos-text'>No tenés turnos programados</p>
          <Link to='https://www.argentina.gob.ar/interior/renaper/turnos-online'>
            <button>
              <p>Solicitalo acá</p>
            </button>
          </Link>
        </div>
      </div>

      <div id="mainButtonsContainer">
        <div id="main-buttons">
          <div id="mis-servicios">
            <p id='servicios-title'>Mis servicios</p>

            <div id="servicios">
              {servicios.map(service => {
                servicekey++;

                return (
                  <div className="service" key={servicekey} onClick={service.onclick}>
                    <div className="service-child">
                      <div className="service-img" style={{ backgroundColor: service.color }}>
                        <img src={service.img} alt="" />
                      </div>
                      <p>{service.name}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div id='separative-line'></div>
          <div id="personalize">
            <p id='personalize-title'>Personalizá Mi Argentina</p>
            <p id='personalize-description'>Elegí los servicios que querés ver en la aplicación</p>
            <button>
              <p>Suscribir servicios</p>
            </button>
          </div>
        </div>
      </div>

      <Footer
        inicio
        footerClass={footerClass}
        footerHome={() => { }}
        footerNovedades={footerNovedades}
        footerTelefono={footerTelefono} />
    </div>
  );
}

export default Home;
