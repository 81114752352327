import React, { useRef, useEffect, useState } from 'react';
import '../css/DniViewer.css';
import Footer from '../components/Footer';

const IFrameWithCustomRatio = ({ src, title, loadAction }) => {
  const iframeRef = useRef(null);
  const [iframeClass, setIframeClass] = useState('iframeHide');
  const [iframeHeight, setIframeHeight] = useState(null);

  const calculateIframeHeight = () => {
    if (iframeRef.current) {
      const width = iframeRef.current.offsetWidth;
      const height = width * 0.747;
      setIframeHeight(height);
      iframeRef.current.style.height = `${height}px`; 
    }
  };

  useEffect(() => {
    calculateIframeHeight(); 
    window.addEventListener('resize', calculateIframeHeight); 

    return () => {
      window.removeEventListener('resize', calculateIframeHeight);
    };
  }, []);

  const showIframe = () => {
    setIframeClass('');
    loadAction();
    setTimeout(() => {
      calculateIframeHeight();
    }, 100);
  };

  return (
    <iframe
      src={src}
      title={title}
      ref={iframeRef}
      style={{ border: 'none', height: iframeHeight ? `${iframeHeight}px` : 'auto' }}
      allowFullScreen
      scrolling="no"
      onLoad={() => {showIframe()}} 
      className={iframeClass}
    />
  );
};

const DniViewer = ({ goToPage, footerClass, userName, userLastname, footerHome, footerNovedades, footerTelefono, goToPage2 }) => {
  const iframeSrc = `${window.location.origin}/dni-component`;
  const [viewerStyle, setViewerStyle] = useState({width: '0', height: '0', opacity: '0', margin: '0'})
  const [viewer2Style, setViewer2Style] = useState()

  const handleIframeLoad = () => {
    setViewerStyle({});
    setViewer2Style({width: '0', height: '0', opacity: '0', margin: '0', display: 'none'});
  };

  const date = new Date();
  let dateDay;
  (date.getDate()).toString().length === 1 ? dateDay = `0${date.getDate()}` : dateDay = `${date.getDate()}`;
  let dateMonth;
  (date.getMonth() + 1).toString().length === 1 ? dateMonth = `0${date.getMonth() + 1}` : dateMonth = `${date.getMonth() + 1}`;
  let dateHours;
  date.getHours().toString().length === 1 ? dateHours = `0${date.getHours()}` : dateHours = `${date.getHours()}`;
  let dateMinutes;
  date.getMinutes().toString().length === 1 ? dateMinutes = `0${date.getMinutes()}` : dateMinutes = `${date.getMinutes()}`;

  const [qrClass, setQrClass] = useState('fake-qr-n');

  const alternateQr = () => {
    if (qrClass === 'fake-qr-n') {
      setQrClass('fake-qr')
    } else {
      setQrClass('fake-qr-n')
    }
  }

  return (
    <div id="app" className='app-dni-viewer'>

      <div id="dniHeader">
        <img src={require('../img/dni-digital/atras.webp')} alt="" id="atras" onClick={() => goToPage()} />
        <p>Dni Digital</p>
      </div>

      <div id="viewerContainer">
        <div id="viewer" style={viewerStyle}>
          <p id='fullName'>{`${userName.toUpperCase()} ${userLastname.toUpperCase()}`}</p>
          <IFrameWithCustomRatio src={iframeSrc} title={'DNI'} loadAction={() => handleIframeLoad()} />
          <p id="lastRefresh">{`Última actualización ${dateDay}/${dateMonth}/${date.getFullYear()} ${dateHours}:${dateMinutes}`}</p>

          <div id="divider-line"></div>

          <div id="detailAndDesactivate">
            <div id="detail" onClick={goToPage2}>
              <img src={require('../img/dni-digital/detail.webp')} alt="" />
              <p>Ver detalle</p>
            </div>
            <div id="divider-line2"></div>
            <div id="desactivate">
              <img src={require('../img/dni-digital/desactivate.webp')} alt="" />
              <p>Desactivar DNI</p>
            </div>
          </div>

          <div id="divider-line"></div>

          <div id="verifyQrContainer">
            <div id="verifyQr" onClick={() => alternateQr()}>
              <div id="qr">
                <img src={require('../img/dni-digital/qr.webp')} alt="" />
                <p>Verifica código QR</p>
              </div>
              <div id="arrow">
                <img src={require('../img/dni-digital/arrow-down.webp')} alt="" />
              </div>
            </div>

            <img src={require('../img/fake-qr.webp')} alt="" className={qrClass} />
          </div>
        </div>

        <div id="viewer2" style={viewer2Style}>
          <div id="refreshText">
            <p>No se pudo descargar tu DNI Digital.</p>
            <p>Presioná ACTUALIZAR para iniciar nuevamente la descarga.</p>
          </div>
          <button>
            <p>Actualizar</p>
          </button>
        </div>
      </div>

      <Footer 
      footerClass={footerClass} 
      footerHome={() => footerHome()}
      footerNovedades={footerNovedades}
      footerTelefono={footerTelefono} />
    </div>
  )
}

export default DniViewer