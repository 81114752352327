import React, { useEffect, useRef } from 'react';
import bwipjs from 'bwip-js';

const PDF417Barcode = ({ value }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current && value) {
      bwipjs.toCanvas(canvasRef.current, {
        bcid: 'pdf417', // PDF417 format
        text: value,
        scale: 2,
        height: 50,
        includetext: false,
        backgroundcolor: 'FFFFFF',
        textcolor: '000000',
      });
    }
  }, [value]);

  return <canvas ref={canvasRef} />;
};

export default PDF417Barcode;
