import React, { useState, useEffect } from 'react';

// Función para convertir base64 a Blob
function base64ToBlob(base64, mime) {
  const byteString = atob(base64.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mime });
}

// Función para crear una URL a partir del Blob
function base64ToImageUrl(base64) {
  const mime = base64.split(',')[0].match(/:(.*?);/)[1];
  const blob = base64ToBlob(base64, mime);
  return URL.createObjectURL(blob);
}

// Componente de React para mostrar la imagen
const Base64Image = ({ base64 }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const url = base64ToImageUrl(base64);
    setImageUrl(url);

    // Limpieza de la URL cuando el componente se desmonta
    return () => URL.revokeObjectURL(url);
  }, [base64]);

  return <img src={imageUrl} alt="" />;
};

export default Base64Image;