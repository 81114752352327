import React, { useState } from 'react';
import '../css/LogIn.css';
import { getFirestore, collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { app } from '../firebase';

const LogIn = ({ goToPage, onUserLogin, goToPage2 }) => {
  const [username, setUsername] = useState(localStorage.getItem('logInUsername' || ''));
  const [password, setPassword] = useState(localStorage.getItem('logInPassword' || ''));
  const [error, setError] = useState('');
  const today = new Date();
  const date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`

  const db = getFirestore(app)

  const checkPayment = async (paymentData, userData) => {
    if (paymentData.paymentDetails.external_reference) {
      const response = await fetch(`https://api.mercadopago.com/v1/payments/${paymentData.data.id}`, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_ACCESS_TOKEN}` // Tu token aquí
        }
      });

      const data = await response.json();

      if (data.status === "approved") {
        const userRef = doc(db, "users", userData.id);  // Referencia al documento del usuario
        const updatedExamplary = userData.extraData.examplary.replace('-notRegistered', '');  // Remueve '-notRegistered'
        await updateDoc(userRef, { "extraData.examplary": updatedExamplary });

        const today = new Date()
        localStorage.setItem('lastDatabaseDate', today.toISOString())
        const userDataUpdated = userData
        userDataUpdated.extraData.examplary = updatedExamplary;
        onUserLogin(userDataUpdated)
      } else {
        setError("No se verificó el pago")
      }

    } else {
      setError("No se verificó el pago.")
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault();

    setError('');

    try {
      const userQuery = query(collection(db, 'users'), where('user', '==', username), where('password', '==', password));
      const querySnapshot = await getDocs(userQuery);
      const userData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))[0];

      if (userData.extraData.examplary.includes('-notRegistered')) {

        try {

          const paymentQuery = query(
            collection(db, 'mercado-pago-webhooks'),
            where('paymentDetails.external_reference', '==', userData.paymentData.external_reference)
          );
          const paymentSnapshot = await getDocs(paymentQuery);
          const paymentData = paymentSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))[0];
          await checkPayment(paymentData, userData);

        } catch (error) {

          try {
            const paymentQuery = query(
              collection(db, 'mercado-pago-webhooks'),
              where('paymentDetails.external_reference', '==', `${username}_${date}`)
            );
            const paymentSnapshot = await getDocs(paymentQuery);
            const paymentData = paymentSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))[0];
            await checkPayment(paymentData, userData);

          } catch (err) {
            setError('No se encontró el pago.')
          }
        }
        
      } else {
        const today = new Date()
        localStorage.setItem('lastDatabaseDate', today.toISOString())
        onUserLogin(userData)
      }

    } catch (err) {
      if (String(err).includes('exceeded')) {
        setError('Servidor caído');
      } else {
        setError('Credenciales incorrectas');
      }
    }

  };

  return (
    <div id='app' className='app-login' style={{ backgroundColor: 'white' }}>
      <div id="login-header">
        <img src={require('../img/log-in/atras.webp')} alt="" id='atras' onClick={() => goToPage()} />
      </div>

      <div id="big-logo">
        <img src={require('../img/log-in/mi-argentina-big-logo.webp')} alt="" />
      </div>

      <form id='login-form' onSubmit={handleLogin}>
        <input
          type="email"
          placeholder='Ingrese su E-Mail'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          autoComplete="email"
        />
        <input
          type="password"
          placeholder='Contraseña'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
        />
        <button type='submit'>
          <p>Ingresar a mi cuenta</p>
        </button>
        {error && <p id='error' style={{ color: 'red' }}>{error}</p>}
        <p id='recover'>Recuperar mi contraseña</p>
      </form>

      <div id="log-in-divider-line"></div>

      <div id="create-account">
        <p id='account-question'>¿No tenés cuenta en Mi Argentina?</p>
        <button onClick={goToPage2}>
          <p>Crear Cuenta</p>
        </button>
      </div>
    </div>
  );
};

export default LogIn;
