import React, { useState } from 'react'
import '../css/MisDocumentos.css';
import Footer from '../components/Footer';

const MisDocumentos = ({ goToPage, goToPage2, footerClass, footerHome, footerNovedades, footerTelefono }) => {
  const [opener, setOpener] = useState(false);
  const [arrow, setArrow] = useState('down');
  const [hideStyle, setHideStyle] = useState({ display: 'none' })

  const checkOpener = () => {
    if (opener) {
      setOpener(false);
      setArrow('down');
      setHideStyle({ display: 'none' })
    } else {
      setOpener(true);
      setArrow('up');
      setHideStyle({});
    }
  }


  return (
    <div id="app" className='app-mis-documentos'>
      <div id="misDocumentosHeader">
        <img id='atras' src={require('../img/mis-documentos/atras.webp')} alt="" onClick={() => { goToPage() }} />
        <p>Mis documentos</p>
      </div>
      <div id="misDocumentosHeader-continue">
        <h2>Tus documentos importantes, en un solo lugar.</h2>
        <img src={require('../img/mis-documentos/documentos-importantes-img.webp')} alt="" />
      </div>
      <div id="documentOpenerContainer">
        <div id="documentOpener">
          <div id="opener" onClick={() => checkOpener()}>
            <img id='dni-logo' src={require('../img/mis-documentos/documento.webp')} alt="" />
            <div id="divider-line"></div>
            <p>Documento Nacional <br /> de Identidad (DNI)</p>
            <img id='arrow-logo' src={require(`../img/mis-documentos/arrow-${arrow}.webp`)} alt="" />
          </div>

          <div id="divider-line2" style={hideStyle}></div>

          <div id="dni-viewer-section" style={hideStyle}>
            <button onClick={() => goToPage2()}>
              <p>Ver DNI digital</p>
            </button>
            <p id='suministratedBy'>Información suministrada por</p>
            <p id='renaper'>RENAPER</p>
          </div>

          <div id="divider-line2" style={hideStyle}></div>

          <div id="askDni" style={hideStyle}>
            <p>Recordá que podés solicitar el DNI para vos y para tus hijos y tenerlo disponible en la App.</p>

            <button>
              <p>Solicitar DNI Digital</p>
            </button>
          </div>

          <div id="divider-line2" style={hideStyle}></div>

          <div id="changeDni" style={hideStyle}>
            <img src={require('../img/mis-documentos/cambiar-dni.webp')} alt="" />
            <p>Cambié o voy a cambiar el dispositivo</p>
          </div>
        </div>
      </div>
      <Footer
        footerClass={footerClass}
        footerHome={() => footerHome()}
        footerNovedades={footerNovedades}
        footerTelefono={footerTelefono} />
    </div>
  )
}

export default MisDocumentos