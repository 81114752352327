import React from 'react';
import '../css/MisTurnos.css'
import Footer from '../components/Footer'

const MisTurnos = ({ footerClass, footerHome, footerNovedades, footerTelefono }) => {
  return (
    <div id="app" className='app-mis-turnos'>
      <div id="misTurnosHeader">
        <img id='atras' src={require('../img/mis-turnos/atras.webp')} alt="" onClick={footerHome} />
        <p>Mis turnos</p>
      </div>

      <div id="misTurnosHeader-continue">
        <h2>Sacá fácil turnos en línea. Nosotros te lo recordamos.</h2>
        <img src={require('../img/mis-turnos/turnos.webp')} alt="" />
      </div>
      <div id="sacarTurnoContainer">
        <div id="sacarTurno">
          <img src={require('../img/mis-turnos/turnos-big-logo.webp')} alt="" />
          <p>No tenés turnos programados</p>

          <div id="divider-line"></div>

          <button>
            <p>Sacar turno</p>
          </button>
        </div>
      </div>

      <Footer
        footerHome={footerHome}
        footerNovedades={footerNovedades}
        footerTelefono={footerTelefono}
        footerClass={footerClass} />
    </div>
  )
}

export default MisTurnos