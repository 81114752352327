import React, { useState, useRef } from 'react'
import imageCompression from 'browser-image-compression';
import { collection, query, getDocs, doc, setDoc, getFirestore, where } from 'firebase/firestore';
import { app } from '../firebase';
import SignatureCanvas from 'react-signature-canvas';

const EditData = ({ user, goToPage }) => {
  const [firstName, setFirstName] = useState(user.data.name);
  const [lastName, setLastName] = useState(user.data.lastname);
  const [dni, setDni] = useState(user.data.dni);
  const [address, setAddress] = useState(user.data.home);
  const [gender, setGender] = useState(user.data.sex === 'M' ? 'Masculino' : 'Femenino');
  const [dateOfBirth, setDateOfBirth] = useState(`20${user.data.dateOfBirth.year}-${user.data.dateOfBirth.month}-${user.data.dateOfBirth.day}`);

  const [nationality, setNationality] = useState(user.extraData.nationality);
  const [examplary, setExamplary] = useState(user.extraData.examplary);
  const [tramitNumber, setTramitNumber] = useState(user.extraData.tramitNumber);
  const [emissionDate, setEmissionDate] = useState(`20${user.extraData.emissionDate.year}-${user.extraData.emissionDate.month}-${user.extraData.emissionDate.day}`);
  const [expirationDate, setExpirationDate] = useState(`20${user.extraData.expirationDate.year}-${user.extraData.expirationDate.month}-${user.extraData.expirationDate.day}`);

  const [cuilLastNumber, setCuilLastNumber] = useState(user.otherData.cuilLastNumber);
  const [securityNumber1, setSecurityNumber1] = useState(user.otherData.securityNumber1);
  const [securityNumber2, setSecurityNumber2] = useState(user.otherData.securityNumber2);
  const [securityNumber3, setSecurityNumber3] = useState(user.otherData.securityNumber3);
  const [securityNumber4, setSecurityNumber4] = useState(user.otherData.securityNumber4);

  const [base64Image, setBase64Image] = useState(user.profilePhoto);
  const externalReference = user?.paymentData?.external_reference || null;
  const [optionalData, setOptionalData] = useState('none');
  const db = getFirestore(app);
  const sigCanvas = useRef(null); // Referencia al lienzo de firma
  const [firmaURL, setFirmaURL] = useState(user.signature || '');

  const optionalDataShow = () => {
    if (optionalData === 'none') {
      setOptionalData('block')
    } else {
      setOptionalData('none')
    }
  }

  const clearSignature = () => {
    sigCanvas.current.clear();
    setFirmaURL('');
  };

  const handleSignatureEnd = () => {
    const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    setFirmaURL(dataURL);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!user.user.includes('ejemplo') && !user.user.includes('prueba')) {

      if (base64Image === "") {
        setCreated("Imagen vacía.");
        return;
      }

      setCreated("Reemplazando documento...");

      const checkedSex = gender === 'Masculino' ? 'M' : 'F';
      const [dateOfBirthYear, dateOfBirthMonth, dateOfBirthDay] = dateOfBirth.split('-');

      const checkedNationality = nationality === '' ? 'Argentina' : nationality;
      const checkedExamplary = examplary === '' ? 'A' : examplary;
      const checkedTramitNumber = tramitNumber === '' ? '0064929313892648' : tramitNumber;
      const emissionDate2 = emissionDate === '' ? '2023-08-08' : emissionDate;
      const [emissionDateYear, emissionDateMonth, emissionDateDay] = emissionDate2.split('-');
      const expirationDate2 = expirationDate === '' ? '2038-08-08' : expirationDate;
      const [expirationDateYear, expirationDateMonth, expirationDateDay] = expirationDate2.split('-');

      const checkedCuilLastNumber = cuilLastNumber === '' ? Math.floor(Math.random() * 10) : cuilLastNumber;
      const checkedSecurityNumber1 = securityNumber1 === '' ? Math.floor(Math.random() * 10) : securityNumber1;
      const checkedSecurityNumber2 = securityNumber2 === '' ? Math.floor(Math.random() * 10) : securityNumber2;
      const checkedSecurityNumber3 = securityNumber3 === '' ? Math.floor(Math.random() * 10000000) : securityNumber3;
      const checkedSecurityNumber4 = securityNumber4 === '' ? Math.floor(Math.random() * 10) : securityNumber4;

      const newData = {
        user: user.user,
        password: user.password,
        profilePhoto: base64Image,
        signature: firmaURL,
        data: {
          name: firstName,
          lastname: lastName,
          dni: dni,
          home: address,
          sex: checkedSex,
          dateOfBirth: {
            day: dateOfBirthDay,
            month: dateOfBirthMonth,
            year: dateOfBirthYear.slice(-2)
          }
        },
        otherData: {
          cuilLastNumber: checkedCuilLastNumber,
          securityNumber1: checkedSecurityNumber1,
          securityNumber2: checkedSecurityNumber2,
          securityNumber3: checkedSecurityNumber3,
          securityNumber4: checkedSecurityNumber4
        },
        extraData: {
          nationality: checkedNationality,
          examplary: checkedExamplary,
          tramitNumber: checkedTramitNumber,
          emissionDate: {
            day: emissionDateDay,
            month: emissionDateMonth,
            year: emissionDateYear.slice(-2)
          },
          expirationDate: {
            day: expirationDateDay,
            month: expirationDateMonth,
            year: expirationDateYear.slice(-2)
          }
        },
        paymentData: {
          external_reference: externalReference
        }
      };

      try {
        const q = query(
          collection(db, "users"),
          where("user", "==", user.user),
          where("password", "==", user.password)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Reemplazar documentos de manera asincrónica y controlar el estado de cada operación
          const updatePromises = querySnapshot.docs.map(async (docSnapshot) => {
            const docRef = doc(db, "users", docSnapshot.id);
            await setDoc(docRef, newData);
          });

          await Promise.all(updatePromises); // Esperar hasta que todas las promesas se completen
          setCreated("Documento reemplazado exitosamente.");

          // Limpiar datos de localStorage
          [
            'loggedInUser', 'name', 'lastname', 'sex', 'home', 'id', 'dni', 'dateOfBirth',
            'nationality', 'examplary', 'tramitNumber', 'emissionDate', 'expirationDate',
            'cuilLastNumber', 'securityNumber1', 'securityNumber2', 'securityNumber3',
            'securityNumber4', 'username', 'password', 'profilePhoto', 'lastDatabaseDate',
            'signature'
          ].forEach(item => localStorage.removeItem(item));

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          setCreated("No se encontró ningún documento que coincida.");
        }
      } catch (error) {
        console.error("Error al reemplazar el documento:", error);
        setCreated("Error al reemplazar el documento.");
      }
    } else {
      setCreated('Esta es una cuenta de prueba/ejemplo.');
    }
  };


  const [created, setCreated] = useState('')

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const maxSize = 0.50 * 1024 * 1024; // 750KB

    if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp')) {
      if (file.size > maxSize) {
        const options = {
          maxSizeMB: 0.60,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
          fileType: file.type,
        };

        try {
          const compressedFile = await imageCompression(file, options);
          const reader = new FileReader();
          reader.onloadend = () => {
            setBase64Image(reader.result);
          };
          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error('Error compressing the image:', error);
          alert('Error al comprimir la imagen.');
        }
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      event.target.value = '';
      setBase64Image('');
      alert('Por favor subir un archivo JPG, PNG, o WEBP.');
    }
  };

  return (
    <div id='app' className='app-create-account' style={{ backgroundColor: 'white' }}>
      <div id="login-header">
        <img src={require('../img/log-in/atras.webp')} alt="" id='atras' onClick={goToPage} />
      </div>

      <h2>Editar datos</h2>

      {user ? (
        <form onSubmit={handleLogin}>

          <h3 style={{ textDecoration: 'underline' }}>Datos obligatorios</h3>

          <div>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => { setFirstName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, "")) }}
              required
              placeholder='Nombre/s  (Máximo 2)'
            />
          </div>
          <div>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value.replace(/[^a-zA-Z0-9]/g, ""))}
              required
              placeholder='Apellido  (Máximo 1)'
            />
          </div>
          <div>
            <input
              type="text"
              id="dni"
              value={dni}
              onChange={(e) => {
                setDni(e.target.value.replace(/[^0-9]/g, ""))
                if (dni.length >= 8) {
                  setDni(e.target.value.substring(0, 8));
                }
              }}
              required
              placeholder='DNI'
            />
          </div>
          <div>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ""))}
              required
              placeholder='Calle, ciudad y provincia'
            />
          </div>
          <div>
            <label htmlFor="gender">Sexo:</label>
            <select
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Seleccione</option>
              <option value="Femenino">Femenino</option>
              <option value="Masculino">Masculino</option>
            </select>
          </div>
          <div>
            <label htmlFor="dateOfBirth">Fecha de nacimiento:</label>
            <input
              type="date"
              id="dateOfBirth"
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              required
            />
          </div>
          <div>
            <label id="profileImageLabel" htmlFor="profileImage">Elegir foto de tu cara (Estilo DNI)</label>
            <input
              type="file"
              id="profileImage"
              accept="image/jpeg, image/png, image/webp"
              onChange={handleFileChange}
            />
            {base64Image && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h3>Imagen subida:</h3>
                <img src={base64Image} alt="Uploaded" id='uploaded-image' style={{ aspectRatio: '77/100', maxWidth: '100%' }} />
              </div>
            )}
          </div>

          <div id='firma-div'>
          <h3>Firma Digital</h3>
          <SignatureCanvas
            ref={sigCanvas}
            onEnd={handleSignatureEnd} // Guardar firma automáticamente al terminar de dibujar
            canvasProps={{ width: 500, height: 200, className: 'firma-canvas', style: { border: '1px solid black' } }}
          />
          <button type="button" onClick={clearSignature}>Limpiar Firma</button>
        </div>

        {firmaURL && firmaURL !== '' && (
          <div>
            <h3>Vista previa de la firma:</h3>
            <img src={firmaURL} alt="Firma" />
          </div>
        )}

          {/* Datos opcionales */}

          <div id='optional-data'>
            <div style={{ all: 'unset' }} onClick={optionalDataShow}>
              <h3 style={{ textDecoration: 'underline' }}>Datos opcionales (se establecen de forma automática)</h3>
              <p>(Haz click para dezplegar)</p>
            </div>

            <div id='optional-data-form' style={{ all: 'unset', display: optionalData }}>
              <div style={{ paddingTop: '10px' }}>
                <input
                  type="text"
                  id="nationality"
                  value={nationality}
                  onChange={(e) => setNationality(e.target.value.replace(/[^a-zA-Z0-9]/g, ""))}
                  placeholder='Nacionalidad (Nombre del país)'
                />
              </div>
              <div>
                <label id='examplaryLabel' htmlFor="examplary">Ejemplar:</label>
                <select
                  id="examplary"
                  value={examplary}
                  onChange={(e) => setExamplary(e.target.value)}
                >
                  <option value="">Seleccione</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                </select>
              </div>
              <div>
                <input
                  type="text"
                  id="tramitNumber"
                  value={tramitNumber}
                  onChange={(e) => {
                    setTramitNumber(e.target.value.replace(/[^0-9]/g, ""));
                    if (tramitNumber.length >= 15) {
                      setTramitNumber(e.target.value.substring(0, 15));
                    }
                  }}
                  placeholder='Número de trámite (15 dígitos)'
                />
              </div>
              <div>
                <label htmlFor="emissionDate">Fecha de emisión:</label>
                <input
                  type="date"
                  id="emissionDate"
                  value={emissionDate}
                  onChange={(e) => setEmissionDate(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="expirationDate">Fecha de expiración:</label>
                <input
                  type="date"
                  id="expirationDate"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                />
              </div>
              <div>
                <input
                  type="text"
                  id="cuilLastNumber"
                  value={cuilLastNumber}
                  onChange={(e) => {
                    setCuilLastNumber(e.target.value.replace(/[^0-9]/g, ""));
                    if (cuilLastNumber.length >= 1) {
                      setCuilLastNumber(e.target.value.substring(0, 1))
                    }
                  }}
                  placeholder='Último dígito del CUIL'
                />
              </div>
              <div>
                <input
                  type="text"
                  id="securityNumber1"
                  maxLength={1}
                  value={securityNumber1}
                  onChange={(e) => {
                    setSecurityNumber1(e.target.value.replace(/[^0-9]/g, ""));
                    if (securityNumber1.length >= 1) {
                      setSecurityNumber1(e.target.value.substring(0, 1))
                    }
                  }}
                  placeholder='Número de seguridad 1 (1 dígito)'
                />
              </div>
              <div>
                <input
                  type="text"
                  id="securityNumber2"
                  maxLength={1}
                  value={securityNumber2}
                  onChange={(e) => {
                    setSecurityNumber2(e.target.value.replace(/[^0-9]/g, ""));
                    if (securityNumber2.length >= 1) {
                      setSecurityNumber2(e.target.value.substring(0, 1))
                    }
                  }}
                  placeholder='Número de seguridad 2 (1 dígito)'
                />
              </div>
              <div>
                <input
                  type="text"
                  id="securityNumber3"
                  minLength={7}
                  maxLength={7}
                  value={securityNumber3}
                  onChange={(e) => {
                    setSecurityNumber3(e.target.value.replace(/[^0-9]/g, ""));
                    if (securityNumber3.length >= 7) {
                      setSecurityNumber3(e.target.value.substring(0, 1))
                    }
                  }}
                  placeholder='Número de seguridad 3 (7 dígitos)'
                />
              </div>
              <div>
                <input
                  type="text"
                  id="securityNumber4"
                  maxLength={1}
                  value={securityNumber4}
                  onChange={(e) => {
                    setSecurityNumber4(e.target.value.replace(/[^0-9]/g, ""));
                    if (securityNumber4.length >= 1) {
                      setSecurityNumber4(e.target.value.substring(0, 1))
                    }
                  }}
                  placeholder='Número de seguridad 4 (1 dígito)'
                />
              </div>
            </div>
          </div>
          <p id='paymentDetail' style={{ marginTop: "5px" }}>Si experimentó algún error, por favor, <b>contactenos</b> vía TikTok  y se lo solucionamos.</p>
          <button id='contact' onClick={(e) => { e.preventDefault(); window.open("https://www.tiktok.com/@mi_argentina_falso", "Contactar", 'fullscreen=yes') }}>
            <img src={require('../img/tiktok-logo.png')} alt="" />
            <p><b>Contactar</b></p>
          </button>
          <p id='created'>{created}</p>
          <div id="buttonContainer">
            <button type="submit" style={{ marginBottom: "10px" }}>
              <p>Editar datos</p>
            </button>
          </div>
        </form>
      ) : null}
    </div>
  )
}

export default EditData