import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import '../css/Telefonos.css';

const Telefono = ({ number, content }) => {
  return (
    <Link to={`tel:${number}`}>
      <div className="telefono">
        <h2>{number}</h2>
        <div id="divider-line2"></div>
        <p>{content}</p>
        <img src={require('../img/telefonos/telefono.jpg')} alt="" />
      </div>
    </Link>
  )
}

const Telefonos = ({footerHome, footerNovedades, footerClass, goToPage}) => {
  return (
    <div id="app" className='app-telefonos'>
      <Header goToPage={goToPage} />

      <div id="telefonosF">
        <Telefono number='911' content='Central de emergencias nacional' />
        <Telefono number='144' content='Violencia de género' />
        <Telefono number='107' content='Emergencias Médicas' />
        <Telefono number='100' content='Bomberos' />
        <Telefono number='102' content='La línea de las chicas y los chicos' />
        <Telefono number='103' content='Defensa Civil' />
        <Telefono number='106' content='Emergencia Náutica' />
        <Telefono number='135' content='Asistencia al Suicida' />
        <Telefono number='141' content='Ayuda Serdonar' />
        <Telefono number='142' content='Menores Extraviados' />
        <Telefono number='145' content='Denuncia de trata' />
        <Telefono number='149' content='Asistencia a víctimas de siniestros viales (op. 2)' />
        <Telefono number='168' content='Discriminación, racismo y xenofobia' />
      </div>

      <Footer 
        footerHome={footerHome} 
        footerNovedades={footerNovedades}
        footerClass={footerClass}
        footerTelefono={() => {}}
        telefonos />
    </div>
  )
}

export default Telefonos