import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCDZSAQjE8iUdMOPpZFJieV0cBS3hcp_Rw",
    authDomain: "mi4rgnt1n4-67bd5.firebaseapp.com",
    projectId: "mi4rgnt1n4-67bd5",
    storageBucket: "mi4rgnt1n4-67bd5.appspot.com",
    messagingSenderId: "317322111735",
    appId: "1:317322111735:web:0a184c0e77a8c9f97801f2",
    measurementId: "G-FFBKP0HTFV"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth }
