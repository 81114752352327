import React from 'react'
import '../css/Header.css';

const Header = ({goToPage}) => {

  return (
    <div id="header">
      <div id="images">
        <img id="hamburger" src={require('../img/hamburger-menu-logo.webp')} alt="" onClick={() => goToPage()} />
        <img id='main-logo' src={require('../img/header-logo.webp')} alt="" />
        <img id="user-logo" src={require('../img/header-user-logo.webp')} alt="" />
      </div>
    </div>
  )
}

export default Header