import React, { useState } from 'react';
import '../css/DniPin.css';
import Footer from '../components/Footer';

const DniPin = ({ goToPage, goToPage2, footerClass, footerHome, footerNovedades, footerTelefono }) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const [inputStyle, setInputStyle] = useState({});

  const checkPin = (e) => {
    e.preventDefault();

    if (true) {
      goToPage2();
    } else {
      setError('PIN incorrecto');
      setInputStyle({ marginBottom: '0' });
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= 6) {
      setPin(value);
      e.target.setCustomValidity('');
      setError('');
      setInputStyle({});
    }
  };

  return (
    <div id="app" className='app-dni-pin'>
      <div id="DniHeader">
        <img
          id="atras"
          src={require('../img/dni-pin/atras.webp')}
          alt=""
          onClick={() => goToPage()}
        />
        <p>Dni Digital</p>
      </div>

      <div id="enterPin">
        <p id="title">Ingresá tu PIN de acceso</p>
        <form onSubmit={checkPin}>
          <input
            type="number"
            placeholder="123456"
            maxLength={6}
            value={pin}
            onChange={handleInputChange}
            onInvalid={(e) => e.target.setCustomValidity('PIN incorrecto')}
            onInput={(e) => e.target.setCustomValidity('')}
            style={inputStyle}
          />
          {error && <p id="incorrect-password">{error}</p>}
          <button type='submit'>
            <p>Ingresar</p>
          </button>
        </form>

        <p id="forgotPin">Olvidé mi PIN</p>
      </div>

      <Footer 
        footerClass={footerClass} 
        footerHome={() => footerHome()}
        footerNovedades={footerNovedades}
        footerTelefono={footerTelefono} />
    </div>
  );
};

export default DniPin;
