import React from 'react'
import '../css/MisVehiculos.css'
import Footer from '../components/Footer'

const MisVehiculos = ({footerClass, footerHome, footerNovedades, footerTelefono}) => {
  return (
    <div id="app" className='app-mis-vehiculos'>
      <div id="misVehiculosHeader">
        <img src={require('../img/mis-vehiculos/atras.webp')} alt="" id="atras" onClick={footerHome} />
        <p>Mis vehículos</p>
      </div>

      <div id="misVehiculosHeader-continue">
        <h2>Los papeles de tu vehículo siempre en tu teléfono.</h2>
        <img src={require('../img/mis-vehiculos/papeles-del-vehiculo.webp')} alt="" />
      </div>

      <div id="addServices">
        <p id='title'>Agregá servicios</p>
        <p id='description'>Asociá servicios para ver tu licencia de conducir y las cédulas de vehículos asociados a tu DNI.</p>
        <button>
          <p>Agregar servicios</p>
        </button>
      </div>

      <Footer 
        footerClass={footerClass}
        footerNovedades={footerNovedades}
        footerHome={footerHome}
        footerTelefono={footerTelefono} />
    </div>
  )
}

export default MisVehiculos