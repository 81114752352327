import React, { useState, useEffect } from 'react';
import '../css/Novedades.css';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import novedades from '../json/novedades.json';

const Novedades = ({ footerClass, goToPage, footerHome, footerTelefono }) => {
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    // Verificar si el JSON está vacío
    if (novedades.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, []);

  return (
    <div id="app" className='app-novedades' style={{ backgroundColor: 'white' }}>
      <Header goToPage={goToPage} />

      <div id="novedades-section">
        {
          isEmpty ? (
            <div id='sin-novedades'>
              <img src={require('../img/novedades/novedades-big-logo.webp')} alt="Novedades logo" />
              <p id='no-hay-novedades'>No hay novedades para mostrar en este momento.</p>
              <button onClick={() => footerHome()}>
                <p>Volver al inicio</p>
              </button>
            </div>
          ) : (
            novedades.map((novedad, index) => (
              <Link to={novedad.link} key={index}>
                <div className="novedad">
                  <h5>{novedad.title}</h5>
                  <p>{novedad.description}</p>
                </div>
              </Link>
            ))
          )
        }
      </div>

      <Footer
        novedades
        footerClass={footerClass}
        footerHome={footerHome}
        footerNovedades={() => { }}
        footerTelefono={footerTelefono}
      />
    </div>
  )
}

export default Novedades;
