import React from 'react';
import '../css/PreviousLogIn.css';
import { app } from '../firebase';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const PreviousLogIn = ({ goToPage, onUserLogin, goToPage2 }) => {
  const username = localStorage.getItem('username');
  const password = localStorage.getItem('password');
  const lastDatabaseDateString = localStorage.getItem('lastDatabaseDate');

  const handleLogin = async () => {
    // Verificar si los datos ya están en el localStorage
    const localUsername = localStorage.getItem('username');
    const localPassword = localStorage.getItem('password');
    const today = new Date();
    const lastDatabaseDate = new Date(lastDatabaseDateString);

    if (username && password && lastDatabaseDate) {
      const diferenciaEnMs = today - lastDatabaseDate;
      const diferenciaEnDias = diferenciaEnMs / (1000 * 60 * 60 * 24);

      if (localUsername === username && localPassword === password && diferenciaEnDias < 10) {
        // Si los datos en localStorage coinciden con las credenciales, crear un objeto de usuario simulado
        const userData = {
          id: localStorage.getItem('id'),
          data: {
            name: localStorage.getItem('name'),
            lastname: localStorage.getItem('lastname'),
            sex: localStorage.getItem('sex'),
            dateOfBirth: JSON.parse(localStorage.getItem('dateOfBirth')),
            home: localStorage.getItem('home'),
            dni: localStorage.getItem('dni'),
          },
          user: localUsername,
          password: localPassword,
          profilePhoto: localStorage.getItem('profilePhoto'),
          signature: localStorage.getItem('signature'),
          otherData: {
            cuilLastNumber: localStorage.getItem('cuilLastNumber'),
            securityNumber1: localStorage.getItem('securityNumber1'),
            securityNumber2: localStorage.getItem('securityNumber2'),
            securityNumber3: localStorage.getItem('securityNumber3'),
            securityNumber4: localStorage.getItem('securityNumber4'),
          },
          extraData: {
            nationality: localStorage.getItem('nationality'),
            examplary: localStorage.getItem('examplary'),
            tramitNumber: localStorage.getItem('tramitNumber'),
            emissionDate: JSON.parse(localStorage.getItem('emissionDate')),
            expirationDate: JSON.parse(localStorage.getItem('expirationDate')),
          },
        };

        // Llamar a onUserLogin con los datos recuperados del localStorage
        setTimeout(() => {
          onUserLogin(userData);
        }, 100);
        return;
      }

      // Si no coinciden o no están en el localStorage, proceder con la consulta a la base de datos
      const db = getFirestore(app);
      try {
        const userQuery = query(collection(db, 'users'), where('user', '==', username), where('password', '==', password));
        const querySnapshot = await getDocs(userQuery);
        const userData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))[0];

        const today = new Date()
        localStorage.setItem('lastDatabaseDate', today.toISOString())

        // Si se encuentra el usuario, almacenar los datos en localStorage
        onUserLogin(userData);

      } catch (err) {
        console.error('Error al autenticar:', err);
      }
    }
  };

  handleLogin()

  

  return (
    <div id="app" className='app-previous-login'>

      <div id="giant-logo">
        <img src={require('../img/previous-log-in/giant-logo.webp')} alt="" />
        <div id="loading" style={username && password ? { display: 'block' } : { display: 'none' }}>
          <p>Iniciando sesión...</p>
        </div>
      </div>

      <div id="previous-login-footer">
        <button id='button1' onClick={() => goToPage()}>
          <p>Ingresar con mi cuenta</p>
        </button>
        <div id="divider-line"></div>
        <p id='account-question'>¿No tenés cuenta en Mi Argentina?</p>
        <button id='button2' onClick={goToPage2}>
          <p>Crear una cuenta</p>
        </button>
      </div>
    </div>
  )
}

export default PreviousLogIn;
