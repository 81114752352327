import React from 'react'
import Footer from '../components/Footer'
import '../css/MisHijos.css'

const MisHijos = ({ footerClass, footerHome, footerNovedades, footerTelefono }) => {
  return (
    <div id="app" className='app-mis-hijos'>
      <div id="misHijosHeader">
        <img id='atras' src={require('../img/mis-hijos/atras.webp')} alt="" onClick={footerHome} />
        <p>Mis hijos</p>
      </div>

      <div id="misHijosHeader-continue">
        <h2>Ahora podés asociar a tus hijos menores en tu perfil</h2>
        <img src={require('../img/mis-hijos/hijos.webp')} alt="" />
      </div>

      <div id="hijos-info">
        <img src={require('../img/mis-hijos/hijos-big-logo.webp')} alt="" />
        <p id="title">Asociá a tus hijos menores a tu perfil para acceder a sus documentos.</p>

        <div id="divider-line"></div>

        <p id="description">Recordá que solo vas a poder asociar a tus hijos menores de 18 años que estén registrados en Renaper.</p>

        <button>
          <p>Asociar un hijo</p>
        </button>
      </div>

      <Footer
        footerClass={footerClass}
        footerHome={footerHome}
        footerNovedades={footerNovedades}
        footerTelefono={footerTelefono}
      />
    </div>
  )
}

export default MisHijos